import 'bootstrap/dist/css/bootstrap.min.css';


function SECCION_DOS_PRE() {
  return (

    <section className="seccion_dos_pre" id='porque' style={{background:'url(imagenes/sect_prelocs.webp)',backgroundRepeat: 'no-repeat', backgroundSize:'cover', backgroundPosition:'center'}}>
        <div className="container">
            <h3 tabIndex={0}>
                ¿Por qué elegir
            </h3>
            <h4 tabIndex={0}>
                una <span> franquicia </span>
            </h4>
            <h4 tabIndex={0}><img src="imagenes/logo_ominim-blanco.webp" alt="imagen logo Ominim"  width="100" height="100" loading="lazy"  tabIndex={0}></img> ?</h4>
            <div className='col-5'>
                <p tabIndex={0}>
                    Porque logramos con un modelo de negocio eficiente, de bajo riesgo comercial, innovador, creativo y rentable, <b>con un retorno de inversión desde los 15 meses, aproximadamente</b>. Para ello contaras con una marca que tiene +18 años en el mercado, y que te brindara asistencia, contención y apoyo continuo en tu gestión.
                </p>
            </div>
            <div class="col-10" style={{}}>
                <h4 tabindex="0">
                   Deberás contar con la Inversión requerida: desde U$S 25.000 (Dólares)
                </h4>
            </div>
        </div>                
    </section>


  );
}

export default SECCION_DOS_PRE;