 
import 'bootstrap/dist/css/bootstrap.min.css';


function Header() {
    return (
        <section id="top">
            <header className="App-header">
                <nav className="navbar navbar-expand-lg navbar-light fixed-top " id="menu">
                        <div className="container-fluid">
                                <a className="navbar-brand" href='#top' id="logoTop" rel="noreferrer"> 
                                        <img src="imagenes/logo_ominim.webp" alt="imagen logo Ominim"  width="100" height="100" loading="lazy"></img>                                   
                                </a>
                            <button class="navbar-toggler collapsed white" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon blanco"></span>
                            </button>
                            <div className="navbar-collapse collapse" id="navbarToggler" >
                                <ul className="nav nav-pills">
                                    <li className="nav-item active">
                                        <a className="nav-link nav_link_color" href="#quienes">Conocenos</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link nav_link_color" href="#historia ">Historia </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link nav_link_color" href="#porque">¿Por qué Ominim? </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link nav_link_color" href="#locales">Locales </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link nav_link_color" href="#contacto">Contacto</a>
                                    </li>
                                </ul>
                            </div>
                             {/*<div className='conteLinked'>
                                <ul className="row">
                                    <li className='col-6'>
                                        <a className="nav-link linkeicon" href="https://www.linkedin.com/company/" alt="link" target="_blank" title="linkedin" rel="noreferrer">
                                            <i className="fa-brands fa-linkedin"></i>
                                        </a>
                                    </li>
                                   <li className='col-6'>
                                        <a className="nav-link faceIcon" href="https://facebook.com/" alt="link" target="_blank" title="facebook">
                                            <i className="fa-brands fa-facebook"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>*/}
                        </div>  
                    </nav>
                    <div className='portada' id='portada' style={{backgroundImage: 'url(imagenes/header_ominim_regalería.webp)',backgroundRepeat: 'no-repeat'}} >
                            <div className="container wow fadeInDown animated" tabIndex="0" alt="titulo">
                                <div className="row">
                                    <div className="col-6">
                                            <h4 tabIndex={0}>
                                                Llevamos
                                            </h4>
                                            <h1 tabIndex={0}>
                                                sonrisas<strong style={{position: 'absolute', color: 'transparent', top: '0px', left: '0px', fontSize:'0.01px', zIndex:'-2'}}>Franquicias rentables en argentina, Franquicias de bazar, Franquicias exitosas, Franquicias de sorpreseria, bazar, Franquicias de regalos, regaleria</strong>
                                            </h1>
                                            <h5 tabIndex={0}>
                                                a tu ciudad
                                            </h5>
                                            <p tabIndex={0}>
                                                Ominim es una sorpresería que ofrece detalles originales para regalar. <br></br>
                                                Donde se pueden encontrar regalos que te van a sorprender, <b>es entrar<br></br>
                                                y no saber por dónde empezar</b>, y donde además las premisas son <br></br>
                                                el diseño y  la innovación.<br></br>
                                                ¡Animate!, sumate a nuestras <b>franquicias</b> y lleva una gran sonrisa a tu ciudad.
                                            </p>
                                            <div className="conteWapp">
                                                <div><img  tabIndex={0} src="imagenes/logo_ominim.webp" alt="imagen logo Ominim"  width="100" height="100" loading="lazy"></img></div>
                                                <div><a href="#contacto" rel="noreferrer" tabIndex={0}>+Consultanos por mas info. </a></div>
                                            </div>
                                    </div>
                                    <div className="col-6">

                                    </div>
                                </div>
                            </div>                    
                    </div>
            </header>
        </section>
        
        

    );
  }
  
  export default Header;