import 'bootstrap/dist/css/bootstrap.min.css';
// import Swiper core and required modules
import { /*Navigation, Pagination,*/ Scrollbar, A11y, Autoplay, Controller, Manipulation  } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useSwiper } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
/*import 'swiper/css/navigation';
import 'swiper/css/pagination';*/
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import 'swiper/css/effect-cards';


function SECCION_DOS() {
  
return (

<section className="seccion_dos" id='locales'  /*style={{backgroundImage: 'url(imagenes/fondo_servicios.png)',backgroundRepeat: 'no-repeat'}}*/ >
<div className='container'>
  <h3 tabIndex={0}>Nuestros Locales</h3>
</div>
<div className="conte_swiper">
    <Swiper
        // install Swiper modules
        modules={[/*Navigation, Pagination,*/ Scrollbar, A11y, Autoplay, Controller,Manipulation ]}               
        init={true}
        loop={true}        
        breakpoints={{
          550: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          551: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 20,
          },
        }}
        slidesPerView={2}
        spaceBetween={20} 
        autoplay={{ delay: 1600, disableOnInteraction: false }}
        /*navigation
        pagination={{ clickable: true }}
        scrollbar={{ draggable: false }}*/
        //onSwiper={(swiper) => console.log(swiper)}
        //onSlideChange={() => console.log('slide change')}
      >
        <SwiperSlide><img width="960" height="1280" loading="lazy" src="imagenes/locales/franquicias-ominim-local-1.webp" alt="franquicias-ominim-local1"></img></SwiperSlide>
        <SwiperSlide><img width="960" height="1280" loading="lazy" src="imagenes/locales/franquicias-ominim-local-15.webp" alt="franquicias-ominim-local15"></img></SwiperSlide>
        <SwiperSlide><img width="960" height="1280" loading="lazy" src="imagenes/locales/franquicias-ominim-local-2.webp" alt="franquicias-ominim-local2"></img></SwiperSlide>
        <SwiperSlide><img width="960" height="1280" loading="lazy" src="imagenes/locales/franquicias-ominim-local-3.webp" alt="franquicias-ominim-local3"></img></SwiperSlide>
        <SwiperSlide><img width="960" height="1280" loading="lazy" src="imagenes/locales/franquicias-ominim-local-14.webp" alt="franquicias-ominim-local14"></img></SwiperSlide>
        <SwiperSlide><img width="960" height="1280" loading="lazy" src="imagenes/locales/franquicias-ominim-local-4.webp" alt="franquicias-ominim-local4"></img></SwiperSlide>
        <SwiperSlide><img width="960" height="1280" loading="lazy" src="imagenes/locales/franquicias-ominim-local-5.webp" alt="franquicias-ominim-local5"></img></SwiperSlide>
        <SwiperSlide><img width="960" height="1280" loading="lazy" src="imagenes/locales/franquicias-ominim-local-6.webp" alt="franquicias-ominim-local6"></img></SwiperSlide>
        <SwiperSlide><img width="960" height="1280" loading="lazy" src="imagenes/locales/franquicias-ominim-local-13.webp" alt="franquicias-ominim-local13"></img></SwiperSlide>
        <SwiperSlide><img width="960" height="1280" loading="lazy" src="imagenes/locales/franquicias-ominim-local-7.webp" alt="franquicias-ominim-local7"></img></SwiperSlide>
        <SwiperSlide><img width="960" height="1280" loading="lazy" src="imagenes/locales/franquicias-ominim-local-14.webp" alt="franquicias-ominim-local14"></img></SwiperSlide>
        <SwiperSlide><img width="960" height="1280" loading="lazy" src="imagenes/locales/franquicias-ominim-local-9.webp" alt="franquicias-ominim-local9"></img></SwiperSlide>
        <SwiperSlide><img width="960" height="1280" loading="lazy" src="imagenes/locales/franquicias-ominim-local-10.webp" alt="franquicias-ominim-local10"></img></SwiperSlide>
        <SwiperSlide><img width="960" height="1280" loading="lazy" src="imagenes/locales/franquicias-ominim-local-11.webp" alt="franquicias-ominim-local11"></img></SwiperSlide>
        <SwiperSlide><img width="960" height="1280" loading="lazy" src="imagenes/locales/franquicias-ominim-local-12.webp" alt="franquicias-ominim-local12"></img></SwiperSlide>
        <SwiperSlide><img width="960" height="1280" loading="lazy" src="imagenes/locales/franquicias-ominim-local-16.webp" alt="franquicias-ominim-local16"></img></SwiperSlide>
      </Swiper>
</div>

<div className='container'>
  <h4 tabIndex={0}>Localidades en las que estamos presentes</h4>
</div>
<div className='container'>
    <div className="conte_swiper localidades" tabIndex={0}>
        <Swiper
            // install Swiper modules
            modules={[/*Navigation, Pagination,*/ Scrollbar, A11y, Autoplay, Controller,Manipulation ]}               
            init={true}
            loop={true} 
            loopAdditionalSlides={29} // Agrega más slides al loop para evitar cortes
            speed={1100} // Hace que la transición sea más fluida
            autoplay={{ delay: 0, disableOnInteraction: false }}
            //slidesPerView={4}
            spaceBetween={10}
            centeredSlides={true} // Centra los slides para una mejor transición
            freeMode={true} // Permite que se mueva de forma más fluida sin cortes
            breakpoints={{
              480: {
                slidesPerView: 3, // 2 slides en pantallas chicas
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 3, // 3 slides en tablets
                spaceBetween: 15,
              },
              1024: {
                slidesPerView: 4, // 4 slides en desktop
                spaceBetween: 20,
              },
            }}
            /*navigation
            pagination={{ clickable: true }}
            scrollbar={{ draggable: false }}*/
            //onSwiper={(swiper) => console.log(swiper)}
            //onSlideChange={() => console.log('slide change')}
          >
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> Casa Central - La Plata, Buenos Aires</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> Alta Gracia, Córdoba.</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> Caseros, Buenos Aires</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> Chivilcoy, Buenos Aires</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> City Bell, Buenos Aires</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> Comodoro Rivadavia, Chubut</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> Mendoza, Mendoza</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> Olavarría, Buenos Aires</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> Pergamino, Buenos Aires</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> Puerto Madryn, Chubut</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> Rada Tilly, Chubut</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> Viedma, Río Negro</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> Casa Central - La Plata, Buenos Aires</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> Alta Gracia, Córdoba.</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> Caseros, Buenos Aires</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> Chivilcoy, Buenos Aires</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> City Bell, Buenos Aires</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> Comodoro Rivadavia, Chubut</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> Mendoza, Mendoza</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> Olavarría, Buenos Aires</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> Pergamino, Buenos Aires</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> Puerto Madryn, Chubut</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> Rada Tilly, Chubut</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> Viedma, Río Negro</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> Casa Central - La Plata, Buenos Aires</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> Alta Gracia, Córdoba.</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> Caseros, Buenos Aires</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> Chivilcoy, Buenos Aires</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> City Bell, Buenos Aires</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> Comodoro Rivadavia, Chubut</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> Mendoza, Mendoza</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> Olavarría, Buenos Aires</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> Pergamino, Buenos Aires</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> Puerto Madryn, Chubut</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> Rada Tilly, Chubut</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> Viedma, Río Negro</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> Casa Central - La Plata, Buenos Aires</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> Alta Gracia, Córdoba.</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> Caseros, Buenos Aires</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> Chivilcoy, Buenos Aires</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> City Bell, Buenos Aires</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> Comodoro Rivadavia, Chubut</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> Mendoza, Mendoza</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> Olavarría, Buenos Aires</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> Pergamino, Buenos Aires</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> Puerto Madryn, Chubut</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> Rada Tilly, Chubut</p></SwiperSlide>
            <SwiperSlide><p><i class="fa-solid fa-location-dot"></i> Viedma, Río Negro</p></SwiperSlide>
          </Swiper>
    </div>
    </div>
</section>
);
}

export default SECCION_DOS;