import 'bootstrap/dist/css/bootstrap.min.css';


function FOOTER() {
    return (
        <div>
                {/*<footer id="footerDos" className="animado wow fadeIn animated">
                    <div className="container"> 
                        <div className="footer2">
                            <div className="container">
                                <div className="row justify-content-between">
                                    <div className="col-3">
                                        <div className="navFooter">
                                            <div>
                                                <img className="logoFuter" src="imagenes/logo_text_blanco.png" alt="imagen" tabIndex="0"></img>
                                            </div>
                                            <ul>
                                                <li></li>
                                                <li className="nav-futer">
                                                    <a className="nav-link nav_link_color" href="#quienes">¿Quiénes somos?</a>
                                                </li>
                                                <li className="nav-futer">
                                                <a className="nav-link nav_link_color" href="#servicios ">Servicios </a>
                                                </li>
                                                <li className="nav-futer">
                                                <a className="nav-link nav_link_color" href="#franquicias ">Franquicias </a>
                                                </li>
                                                <li className="nav-futer">
                                                    <a className="nav-link nav_link_color" href="#contacto">Contacto</a>
                                                </li>   
                                            </ul>
                                        </div>
                                    </div>
                                    
                                    <div className="col-3">
                                        <div className="dtsUbic">
                                            <div className="map">
                                                <span> Info Contacto: </span>
                                                
                                                    <ul className="listaDire">
                                                        <li><i class="fa-brands fa-whatsapp"></i> +54 9 11 3463 5951</li>
                                                        <li><i className="fa-solid fa-envelope"></i>  info@franquiciarg.com.ar</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className="conteRsFooter">
                                            <span> Seguinos en: </span>
                                            <ul>
                                                <li>
                                                    <a className="nav-link linkeicon" href="https://www.linkedin.com/company/franquiciarg/" alt="link" target="_blank" title="linkedin" rel="noreferrer">
                                                        <i className="fa-brands fa-linkedin"></i> Linkedin
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                
                    </div>
                </footer>*/}
                <div className="posfuter">
                <div className="container">
                    <div className="row justify-content-between align-items-center">                    
                        <div className="col-6">
                            <p>© 2024 - Todos los derechos reservados</p>
                        </div>
                        <div className="col-6">
                            <p>Diseño y Desarrollo <a href="http://bluesitesweb.com" target="_blank" rel="noopener noreferrer" aria-label='Visitar blueSites'> <img src="imagenes/logo_bluesitesweb.webp" alt="logo blueSitesweb" width="200" height="200" loading="lazy"></img></a></p>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>

    );
  }
  
  export default FOOTER;